"use client"
import { PropsWithChildren } from 'react'
import "@/app/(styles)/navigation.css"

import { ModeToggle } from "@/app/components/DarkMode"

import { signOut } from "next-auth/react"
import Head from "next/head"
import Link from "next/link"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu"

import {
  NavigationMenu,
  NavigationMenuInternalLink,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuPermissionsLink,
  navigationMenuTriggerStyle,
} from "@/app/components/ui/navigation-menu"
import { ChevronDown, Menu } from "lucide-react"
import { Session } from "next-auth"
import { PARSLogo } from "../components/ui/parsLogo"
import { cn } from "../lib"

const Header = ({
  session,
  admin,
  viewAll,
  dashboards,
  hasReviews,
}: {
  readonly session: Session | null
  readonly admin: boolean
  readonly viewAll: boolean
  readonly dashboards: boolean
  readonly hasReviews?: boolean
}) => (
  <>
    <Head>
      <title>PARS</title>
    </Head>
    <nav>
      <NavigationMenu className="z-20 h-full w-full max-w-full pt-2 pb-2 justify-between bg-nav-background text-nav-text dark:bg-nav-background-dark dark:text-nav-text-dark">
        <NavigationMenuList>
          <NavigationMenuItem className="mr-5">
            <PARSLogo />
            {/* eslint-disable-next-line @next/next/no-img-element */}
          </NavigationMenuItem>
        </NavigationMenuList>
        <NavigationMenuView>
          <NavigationMenuList className="justify-start text-center">
            <NavigationMenuItem id="projectsLink">
              <NavigationMenuInternalLink href="/Projects">
                Projects
              </NavigationMenuInternalLink>
            </NavigationMenuItem>
            <NavigationMenuItem id="contractsLink">
              <NavigationMenuInternalLink href="/Contracts">
                Contracts
              </NavigationMenuInternalLink>
            </NavigationMenuItem>
            <NavigationMenuItem id="reportsLink">
              <NavigationMenuInternalLink href="/Reports">
                Reports
              </NavigationMenuInternalLink>
            </NavigationMenuItem>
            {hasReviews && (
              <NavigationMenuItem>
                <NavigationMenuInternalLink href="/Reviews">
                  Reviews
                </NavigationMenuInternalLink>
              </NavigationMenuItem>
            )}
            {viewAll && (
              <NavigationMenuItem>
                <NavigationMenuInternalLink href="/ReportBuilder">
                  Report Builder
                </NavigationMenuInternalLink>
              </NavigationMenuItem>
            )}
            <NavigationMenuItem id="empowerLink">
              <NavigationMenuLink
                href="https://empower.pars.doe.gov/empower-saml/"
                className={navigationMenuTriggerStyle()}
              >
                Empower
              </NavigationMenuLink>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <NavigationMenuPermissionsLink
                groups={["IP2M Facilitators", "IP2M User"]}
                session={session}
                href="https://ip2m.pars.doe.gov/"
                className={navigationMenuTriggerStyle()}
              >
                IP2M METRR
              </NavigationMenuPermissionsLink>
            </NavigationMenuItem>
            <NavigationMenuItem id="wikiLink">
              <NavigationMenuLink
                href="https://wiki.pars.doe.gov/"
                className={navigationMenuTriggerStyle()}
              >
                Help
              </NavigationMenuLink>
            </NavigationMenuItem>
          </NavigationMenuList>
          <NavigationMenuList className="justify-end text-center mr-2">
            <NavigationMenuItem>
              {(<div className="font-medium py-2 px-4 text-center">{session?.userinfo?.name}</div>) ?? (
                <div className="font-medium py-2 px-4 text-sm">{session?.userinfo?.email}</div>
              ) ??
                ""}
            </NavigationMenuItem>
            {dashboards && <DashboardMenu />}
            {admin && <AdminMenu />}

            <NavigationMenuItem
              className={`${navigationMenuTriggerStyle()} cursor-pointer`}
              onClick={() => signOut()}
            >
              Sign Out
            </NavigationMenuItem>
            <NavigationMenuItem>
              <ModeToggle />
            </NavigationMenuItem>
          </NavigationMenuList>
        </NavigationMenuView>
      </NavigationMenu>
    </nav>
  </>
)

export default Header

function NavigationMenuView(props: PropsWithChildren) {
  return (
    <li className="list-none">
      <div className="hidden lg:inline-flex items-center">
        {props.children}
      </div>
      <div className="lg:hidden mr-2">
        <DropdownMenu>
          <DropdownMenuTrigger
            onClick={(e) => e.stopPropagation()}
            className={cn(navigationMenuTriggerStyle())}
          >
            <Menu />
          </DropdownMenuTrigger>
          <DropdownMenuContent className="dropdown-nav list-none lg:hidden w-80 mr-2">
            {props.children}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </li>
  )
}

function DashboardMenu() {
  return (
    <NavigationMenuItem>
      <DropdownMenu>
        <DropdownMenuTrigger
          onClick={(e) => e.stopPropagation()}
          className={cn(navigationMenuTriggerStyle())}
        >
          Dashboards <ChevronDown />
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <Link href="/dashboards/json-uploads">
            <DropdownMenuItem
              onClick={(e) => e.stopPropagation()}
              className={"cursor-pointer"}
            >
              JSON Uploads
            </DropdownMenuItem>
          </Link>
        </DropdownMenuContent>
      </DropdownMenu>
    </NavigationMenuItem>
  )
}

function AdminMenu() {
  return (
    <NavigationMenuItem>
      <DropdownMenu>
        <DropdownMenuTrigger
          onClick={(e) => e.stopPropagation()}
          className={cn(navigationMenuTriggerStyle())}
        >
          Admin <ChevronDown />
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <Link href="/admin/json-dashboard">
            <DropdownMenuItem
              onClick={(e) => e.stopPropagation()}
              className={"cursor-pointer"}
            >
              JSON Dashboard
            </DropdownMenuItem>
          </Link>
          <Link href="/admin/SubProjectAudit">
            <DropdownMenuItem
              onClick={(e) => e.stopPropagation()}
              className={"cursor-pointer"}
            >
              SubProject Audit
            </DropdownMenuItem>
          </Link>
          <Link href="/admin/ManageMetadata">
            <DropdownMenuItem
              onClick={(e) => e.stopPropagation()}
              className={"cursor-pointer"}
            >
              Manage Metadata
            </DropdownMenuItem>
          </Link>
          <Link href="/admin/users-and-contacts">
            <DropdownMenuItem
              onClick={(e) => e.stopPropagation()}
              className={"cursor-pointer"}
            >
              Users and Contacts
            </DropdownMenuItem>
          </Link>
          <Link href="/admin/Documents">
            <DropdownMenuItem
              onClick={(e) => e.stopPropagation()}
              className={"cursor-pointer"}
            >
              Documents
            </DropdownMenuItem>
          </Link>
          <Link href="/admin/reports/acronyms">
            <DropdownMenuItem
              onClick={(e) => e.stopPropagation()}
              className={"cursor-pointer"}
            >
              MSR/QSR Acronyms
            </DropdownMenuItem>
          </Link>
        </DropdownMenuContent>
      </DropdownMenu>
    </NavigationMenuItem>
  )
}
